<div class="card card-body">
  <form [formGroup]='taskForm' class="standardModalForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="form-row">
      <div class="form-group col-md-6">
        <div class="form-row">
          <div class="form-group col-md-8">
            <label for="title">Title:</label>
            <input type="text" class="form-control" formControlName="title" name="{{randomName}}"
                   id="title" autocomplete="off" tabindex="1"
                   [ngClass]="{'is-invalid' : formErrors.title}"
                   (blur)="customVal.logValidationErrors(this.taskForm, formErrors)">
            <div class="invalid-feedback">
              {{this.formErrors.title}}
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="title">Status:</label>
            <select class="form-control" formControlName="taskStatusId" tabindex="2">
              <ng-container *ngFor="let taskStatus of taskStatuses">
                <option *ngIf="taskStatus.active" value="{{taskStatus.taskStatusId}}">{{taskStatus.name}}</option>
              </ng-container>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="error">Dato fejlmeldt:</label>
            <input type="text" class="form-control" formControlName="error" name="{{randomName}}"
                   id="error" autocomplete="off" tabindex="3" myDatepicker [onlyDatePicker]="true"
                   [ngClass]="{'is-invalid' : formErrors.error}"
                   (blur)="customVal.logValidationErrors(this.taskForm, formErrors)">
            <div class="invalid-feedback">
              {{this.formErrors.error}}
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="solved">Dato løst:</label>
            <input type="text" class="form-control" formControlName="solved" name="{{randomName}}"
                   id="solved" autocomplete="off" tabindex="4" myDatepicker [onlyDatePicker]="true"
                   [ngClass]="{'is-invalid' : formErrors.solved}"
                   (blur)="customVal.logValidationErrors(this.taskForm, formErrors)">
            <div class="invalid-feedback">
              {{this.formErrors.solved}}
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="description">Årsag:</label>
            <textarea type="text" class="form-control" formControlName="description" name="{{randomName}}"
                      id="description" [ngClass]="{'is-invalid' : formErrors.description}" rows="8"
                      autocomplete="off" tabindex="5"
                      (blur)="customVal.logValidationErrors(this.taskForm, formErrors)"></textarea>
            <div class="invalid-feedback">
              {{this.formErrors.description}}
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-xs-2">
            <button type="submit" class="btn btn-primary btn-block" tabindex="6"
                    id="submit">
              <span *ngIf="this.data == undefined">Opret status</span>
              <span *ngIf="this.data != undefined">Opdatere status</span>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Vælg services</label>
            <br>
            <div *ngFor="let customerBoxType of customerBoxTypes">
              <mat-checkbox
                (change)="customerBoxType.checked = !customerBoxType.checked; customerBoxTypeChange()"
                [checked]="customerBoxType.checked">{{customerBoxType.name}}</mat-checkbox>
            </div>

            <label for="subject">Emne (Husk der ikke er emne på SMS)</label>
            <input class="form-control" autocomplete="off" name="{{randomName}}" id="subject" tabindex="1"
                   formControlName="subjectNotification">

            <label for="descriptionMessage">Besked</label>
            <textarea class="form-control" rows="5"
                      autocomplete="off" name="{{randomName}}" id="descriptionMessage" tabindex="1"
                      formControlName="descriptionNotification"></textarea>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label
                  *ngIf="this.taskForm.value.subjectNotification == undefined || this.taskForm.value.subjectNotification == '' || this.taskForm.value.descriptionNotification == undefined || this.taskForm.value.descriptionNotification == '' || listData.data.length == 0">Husk
                  at vælg minimum en service kategori og
                  udfyld emne samt besked</label>
              </div>
              <div class="form-group col-md-4">
                <button class="btn btn-success"
                        [disabled]="this.taskForm.value.descriptionNotification == undefined || this.taskForm.value.descriptionNotification == '' || listData.data.length == 0 || lock"
                        (click)="sendSms()">Send sms
                </button>
              </div>
              <div class="form-group col-md-4">
                <button class="btn btn-success"
                        [disabled]="this.taskForm.value.subjectNotification == undefined || this.taskForm.value.subjectNotification == '' || this.taskForm.value.descriptionNotification == undefined || this.taskForm.value.descriptionNotification == '' || listData.data.length == 0 || lock"
                        (click)="sendMail()">Send mail
                </button>
              </div>
            </div>

            <ngx-spinner
              bdColor="#333"
              size="medium"
              color="#FFFF00"
              type="pacman"
              [fullScreen]="true">
              <p style="color: white">Sender sms'er... </p>
            </ngx-spinner>

            <ng-container *ngFor="let smsResponse of smsResponses?.recipients">
              {{smsResponse.msisdn}} - {{smsResponse.dsnstatus}}<br>
            </ng-container>
          </div>

          <div class="form-group col-md-6">
            <label>Kunder med service</label>
            <div class="mat-elevation-z8" *ngIf="listData.data.length > 0">
              <mat-table [dataSource]="listData" matSort>
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Navn</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="email">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.email}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="phone">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.phone}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="loading">
                  <mat-footer-cell *matFooterCellDef>
                    <mat-card *ngIf="isLoading"
                              style="justify-content: center">
                      <mat-progress-spinner
                        color="primary"
                        mode="indeterminate">
                      </mat-progress-spinner>
                    </mat-card>
                  </mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="noData">
                  <mat-footer-cell *matFooterCellDef>
                    Ingen data... [*matFooterRowDef]
                  </mat-footer-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

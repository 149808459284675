import {AbstractControl, FormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';

// This class is used for validating form groups and displaying a error message

@Injectable()
export class CustomValidators {

  // Error message
  static validationMessages = {
    'username': {
      'required': 'Skal være udfyldt',
    },
    'password': {
      'required': 'Skal være udfyldt',
      'passwordNotMatch': 'Adgangskode skal være ens',
      'passwordPolicy': 'Adgangskode skal minimum være 10 tegn, Stort, lille bogstav, 1 Specialtegn og 1 tal',
    },
    'password2': {
      'required': 'Skal være udfyldt',
    },
    'employeeGroup': {
      'required': 'Skal være udfyldt',
    }
  };

  // Is set to true when submit is clicked
  public submitClicked;

  static passwordMatch(control: AbstractControl) {
    const pass = control.get('password').value;
    const confirmPass = control.get('password2').value;
    if (pass === confirmPass) {
      control.get('password').setErrors(null);
      if (pass != null && pass !== '' && !pass.toString().match(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/)) {
        control.get('password').setErrors({'passwordPolicy': true});
      }
      return null;
    } else {
      control.get('password').setErrors({'passwordNotMatch': true});
    }
  }

  static passwordMatch2(control: AbstractControl) {
    const pass = control.get('password').value;
    const confirmPass = control.get('password2').value;
    if (pass === confirmPass && pass != '') {
      control.get('password').setErrors(null);
      if (pass != null && pass !== '' && !pass.toString().match(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/)) {
        control.get('password').setErrors({'passwordPolicy': true});
      }
      return null;
    } else {
      control.get('password').setErrors({'passwordNotMatch': true});
    }
  }

  // Validating all fields in form group and setting the correct error message
  public logValidationErrors(group: FormGroup, formErrors): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl, formErrors);
      } else {
        formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (this.submitClicked === true) ||
          (abstractControl.touched || abstractControl.dirty)) {
          const messages = CustomValidators.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              if (typeof messages === 'undefined') {
                console.log(key + ' har ingen error handling!!!');
              } else {
                formErrors[key] += messages[errorKey] + ' ';
              }
            }
          }
        }
      }
    });
  }
}

import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Task} from '../models/task';

@Injectable({
  providedIn: 'root'
})

export class TaskService {
  constructor(private http: HttpClient) {
  }

  get() {
    return this.http.get('/api/task')
      .pipe(
        map((data: any[]) => {
          return data;
        }));
  }

  insert(task: Task) {
    return this.http.post('api/task', task).pipe(
      map((data: any) => {
        return data;
      }));
  }

  update(task: Task) {
    return this.http.put('/api/task', task)
      .pipe(
        map((data: Task) => {
          return data;
        }));
  }

  delete(taskId) {
    return this.http.delete('/api/task/' + taskId)
      .pipe(
        map((data: Task) => {
          return data;
        }));
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../../../shared/dialogs/dialog.service';
import {Task} from 'src/app/shared/models/task';
import {GlobalFunctionsService} from '../../../shared/services/globalFunctions.service';
import {TaskService} from '../../../shared/services/task.service';
import {TaskComponent} from '../task/task.component';
import {EmployeeService} from "../../../shared/services/employee.service";

@Component({
  selector: 'taskView',
  templateUrl: 'taskView.component.html',
  styleUrls: ['./taskView.component.scss']
})

export class TaskViewComponent implements OnInit {
  constructor(public taskService: TaskService, private fb: FormBuilder, private toastrService: ToastrService,
              public globalFunctionsService: GlobalFunctionsService, private dialog: MatDialog,
              private dialogSrv: DialogService, public employeeService: EmployeeService) {
  }

  listData: MatTableDataSource<Task>;
  displayedColumns: string[] = ['title', 'status', 'category', 'error', 'solved', 'modifiedOn', 'modifiedBy', 'actions'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  searchKey = '';
  dialogRef;

  ngOnInit() {
    this.employeeService.get().subscribe(
      (success) => {
        GlobalFunctionsService.employees = success;
      }, err => {
        this.toastrService.error(err.error.message, err.error);
        console.log(err);
      }
    );

    this.employeeService.getCurrentEmployee().subscribe(
      (success) => {
        GlobalFunctionsService.employee = success;
      }, err => {
        this.toastrService.error(err.error.message, err.error);
        console.log(err);
      }
    );


    this.listData = new MatTableDataSource();
    this.listData.paginator = this.paginator;
    this.load();
  }

  load() {
    this.taskService.get().subscribe(
      success => {
        this.listData.data = success;
        this.listData.sort = this.sort;
      });
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyFilter();
  }

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLocaleLowerCase();
  }

  onCreate() {
    this.dialogRef = this.dialog.open(TaskComponent, GlobalFunctionsService.matDialogConfigStandard());
    this.dialogRef.afterClosed().subscribe(() => {
      this.load();
    });
  }

  onEdit(row) {
    this.dialogRef = this.dialog.open(TaskComponent, GlobalFunctionsService.matDialogConfigStandard(row));
    this.dialogRef.afterClosed().subscribe(() => {
      this.load();
    });
  }

  onDelete(row: Task) {
    if (this.dialogSrv.modalOpen === false) {
      this.dialogSrv.modalOpen = true;
      this.dialogSrv.openConfirmDialog('', 'Er du sikker på du vil slette ' + row.title, 'Ja ', 'Annullere')
        .afterClosed().subscribe(res => {
        if (res) {
          this.taskService.delete(row.taskId).subscribe(
            () => {
              this.load();
              this.toastrService.success('Deaktiveret: ' + row.title, 'Administration');
            }, err => {
              this.toastrService.error(err.error.message, err.error);
              console.log(err);
            }
          );
        }
        this.dialogSrv.modalOpen = false;
      });
    }
  }
}

import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class TaskPublicService {
  constructor(private http: HttpClient) {
  }

  get() {
    return this.http.get('/public/task')
      .pipe(
        map((data: any[]) => {
          return data;
        }));
  }
}

import {Component, OnInit} from '@angular/core';
import {TaskPublicService} from '../../shared/services/taskPublic.service';
import { Loader } from '@googlemaps/js-api-loader';

@Component({
  selector: 'index',
  templateUrl: 'index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {
  constructor(private taskPublicService: TaskPublicService) {
  }

  public tasks = [];
  public open;
  public map: google.maps.Map;

  ngOnInit(): void {
    this.taskPublicService.get().subscribe((res) => {
      this.tasks = res;
    });

    const loader = new Loader({
      apiKey: 'AIzaSyBidVHCAy2qvdaA3C2Al4GrNmku4Qunkew',
      version: 'weekly',
    });

    loader.load().then(() => {
      this.initMap();
    });
  }

  toggleTask(task: any) {
    if (this.open === task.taskId) {
      this.open = undefined;
    } else {
      this.open = task.taskId;
    }
  }



  initMap(): void {
    this.map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        zoom: 8,
        center: { lat: 55.432800, lng: 11.807050},
        gestureHandling: 'greedy',
      }
    );
    // The locations
    const locations = [
      { lat: 55.432800, lng: 11.807050 },
      { lat: 55.230360, lng: 11.758280 },
      ];

    const image = '../../../assets/img/Google-Maps-Pointer-skaleret-1.png';

    // Add some markers to the map.
    // Note: The code uses the JavaScript Array.prototype.map() method to
    // create an array of markers based on a given "locations" array.
    // The map() method here has nothing to do with the Google Maps API.
    const markers = locations.map((location, i) => {
      return new google.maps.Marker({
        position: location,
        map: this.map,
        icon: image

        // label: labels[i % labels.length],
      });
    });

  }
}

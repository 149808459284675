export default {
    'credentials': {
        'clientId': '6119f12d-4776-4493-9ded-11ad2eb30404',
        'tenantId': 'c799d5c2-b1c6-4680-9c77-a887f7d9a79d'
    },
    'configuration': {
        'redirectUri': 'https://localhost:5002/administartion',
        'postLogoutRedirectUri': 'https://localhost:5002'
    },
    'resources': {
        'todoListApi': {
            'resourceUri': '*api/*',
            'resourceScopes': [
                'api://9764d719-36bc-458e-8864-fab9920d9608/access_as_user'
            ]
        }
    }
};

<div class="hero-image">
  <div class="hero-text">
    <h1>DRIFT STATUS</h1>
  </div>
</div>
<div class="row backgroundColor">
  <div class="col-lg-2">
  </div>
  <div class="col-lg-8">
    <div id="driftstatus">
      <div id="drift-header">
        <div id="drift-status">STATUS</div>
        <div id="drift-titel">Titel</div>
        <div id="drift-category">Kategori</div>
        <div id="drift-meldt">DATO FEJLMELDT</div>
        <div id="drift-loest">DATO LØST</div>
        <div id="drift-post">OPRETTET/REDIGERET</div>
      </div>

      <div id="drift-body">
        <ng-container *ngFor="let task of tasks">
          <div class="accordion" (click)="toggleTask(task)">
            <div class="drift-status"><p><i
              class="fas fa-circle taskStatus{{task.taskStatusId}}"></i> {{task.taskStatus.name}}
            </p></div>
            <div class="drift-titel">{{task.title}}</div>
            <div class="drift-category">
              <ng-container *ngFor="let taskCustomerBoxType of task.taskCustomerBoxTypes">
                {{taskCustomerBoxType.customerBoxType.name}}
              </ng-container>
            </div>
            <div class="drift-meldt"><p>{{task.error | danishDate: false}}</p></div>
            <div class="drift-loest"><p>{{task.solved | danishDate: false}}</p></div>
            <div class="drift-post"><p>{{task.modifiedOn | danishDate}}</p></div>
          </div>
          <div class="panel" [style.display]="open == task.taskId ? 'block' : 'none'">
            <p style="white-space:pre-wrap;">{{task.description}}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="blue-banner width-100 background-primary text-center">
  <div class="xxl-text text-center text-white">
    Er der ikke registeret nedbrud? Kontakt os på
    <a class="link-secondary" href="tel:+4570221228">70 221 228</a>!
  </div>
  <a href="#contact">
    <i class="fontawesome-icon fa-arrow-alt-circle-down far circle-no text-white" id="scroll-down-frontpage"></i>
  </a>
</div>

<div class="row backgroundColor" id="contact">
  <div class="col-md">
  </div>

  <div class="col-lg-8">
    <div class="xl-text">KONTAKT OS</div>
    <div class="separator"></div>
    <div class="medium-text">
      <p>
        Ønsker du at høre mere om hvordan vi kan skabe værdi for dig og din virksomhed, har du spørgsmål til et
        specifikt produkt eller noget helt tredje?
        <br>
        Så hører vi meget gerne fra dig! Vi tager altid gerne en kop uforpligtende kaffe og hører nærmere om lige netop
        dine udfordringer.
      </p>
    </div>
    <div class="medium-text mb-5">
      Smid en mail på <a href="mailto:info@itoperators.dk">info@itoperators.dk</a> eller ring til os på <a
      href="tel:+4570221228">70 221 228</a> – Vi glæder os til at høre fra dig!
    </div>
    <div class="row pt-3">
      <div class="col-md">
      </div>
      <div class="col-md-6">
        <img src="../../../assets/img/it-operators-ApS-200x77.jpg" width="200" height="77"
             alt="" class="mb-5">
        <div class="row">
          <div class="col-lg-6 medium-text">
            <div class="d-table-cell pr-2">
              <i class="fa fa-home"></i>
            </div>
            <div class="d-table-cell pl-2">
              <span class="d-block">IT Operators ApS</span>
              <span class="d-block">Jættevej 50B</span>
              <span class="d-block">4100 Ringsted</span>
              <span class="d-block">CVR: 33 25 55 35</span>
            </div>
          </div>
          <div class="col-lg-6 medium-text">
            <div class="d-table-cell pr-2">
              <i class="fa-globe fas"></i>
            </div>
            <div class="d-table-cell pl-2">
              <span class="d-block">IT Operators ApS</span>
              <span class="d-block">Axeltorv 5,</span>
              <span class="d-block">3. th,</span>
              <span class="d-block">4700 Næstved</span>
            </div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-lg-6 medium-text">
            <div class="p-1">
              <a href="tel:+4570221228">
                <i class="fa fa-phone pr-2"></i>
                <span>+45 70 221 228</span>
              </a>
            </div>
            <div class="p-1">
              <a href="mailto:info@itoperators.dk">
                <i class="far fa-envelope pr-2"></i>
                <span>info@itoperators.dk</span>
              </a>
            </div>
          </div>
          <div class="col-lg-6 medium-text">
            <div class="p-1">
              <i class="far fa-clock pr-2"></i>
              <span>Mandag-Fredag: Døgnet rundt</span>
            </div>
            <div class="p-1">
              <i class="far fa-clock pr-2"></i>
              <span>Lørdag-Søndag: Døgnet rundt</span>
            </div>
          </div>
        </div>
        <div class="row pt-5">
          <span class="col-lg-12 large-text">
              Du er altid velkommen forbi, vi giver gerne en kop varm kaffe!
            <i class="fa fa-coffee fa-lg"></i>
          </span>
        </div>
      </div>
      <div class="col-md">
      </div>
    </div>
  </div>
  <div class="col-md">
  </div>
  <!--The div element for the map -->
  <div id="map"></div>
    <div id="footer">
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <div class="mb-2">
          <img src="../../../assets/img/logo.png" alt="">
        </div>
        <span class="medium-text">
          Vi er specialister i værdiskabende IT løsninger der tager udgangspunkt i dine behov og udfordringer. Gennem tæt dialog finder vi frem til- og implementerer de rigtige løsninger der skaber grundlag for en effektiv arbejdsdag såvel som understøtter virksomhedens strategi og målsætninger på den lange bane.
        </span>
      </div>
      <div class="col-md-3 col-sm-12 text-primary">
        <span>UDVALGTE YDELSER</span>
        <ul class="mt-4">
          <li><a href="https://itoperators.dk/#Hosting">Hosting</a></li>
          <li><a href="https://itoperators.dk/#Drift">Drift</a></li>
          <li><a href="https://itoperators.dk/#Support">Support</a></li>
          <li><a href="https://itoperators.dk/#Webudvikling">Webudvikling</a></li>
          <li><a href="https://itoperators.dk/#Backup">Backup</a></li>
          <li><a href="https://itoperators.dk/#Kontakt">Kontakt IT Operators</a></li>
        </ul>
      </div>
      <div class="col-md-3 col-sm-12 small-text">
        <span class="text-primary medium-text mb-4">KONTAKT INFORMATIONER</span>
        <div class="d-block mt-4">
          <div class="d-table-cell pr-2">
            <i class="fa fa-home"></i>
          </div>
          <div class="d-table-cell pl-2">
            <span class="d-block">IT Operators ApS</span>
            <span class="d-block">Jættevej 50B</span>
            <span class="d-block">4100 Ringsted</span>
            <span class="d-block">CVR: 33 25 55 35</span>
          </div>
        </div>
        <div class="d-block">
          <div class="d-table-cell pr-2">
            <i class="fa-globe fas"></i>
          </div>
          <div class="d-table-cell pl-2">
            <span class="d-block">IT Operators ApS</span>
            <span class="d-block">Axeltorv 5,</span>
            <span class="d-block">3. th,</span>
            <span class="d-block">4700 Næstved</span>
          </div>
        </div>
        <div class="p-1">
          <a href="tel:+4570221228">
            <i class="fa fa-phone pr-2"></i>
            <span class="text-white">+45 70 221 228</span>
          </a>
        </div>
        <div class="p-1">
          <a href="mailto:info@itoperators.dk">
            <i class="far fa-envelope pr-2"></i>
            <span class="text-white">info@itoperators.dk</span>
          </a>
        </div>
        <div class="p-1">
          <i class="far fa-clock pr-2"></i>
          <span>Mandag-Fredag: Døgnet rundt</span>
        </div>
        <div class="p-1">
          <i class="far fa-clock pr-2"></i>
          <span>Lørdag-Søndag: Døgnet rundt</span>
        </div>
      </div>
      <div class="col-md-3 col-sm-12">
        <span class="color-primary">VUNDNE PRISER</span>
        <div class="row p-0 mt-5">
          <div class="col-6">
            <img src="../../../assets/img/gazelle-2017-2018.png" alt="" style="margin:5px 5px 35px 5px; height: auto;" height="66" width="180">
            <img src="../../../assets/img/AAA-Silver_5.png" alt="" style="margin:5px 5px 35px 5px; height: auto;" height="66" width="180">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {Component} from '@angular/core';
import {GlobalFunctionsService} from '../../shared/services/globalFunctions.service';
import {DialogService} from '../../shared/dialogs/dialog.service';
import {NavigationStart, Router} from '@angular/router';
import {AuthenticationService} from '../auth/authenticationService';
import {MatDialog} from '@angular/material/dialog';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {EventMessage, EventType} from '@azure/msal-browser';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  constructor(public globalFunctionsService: GlobalFunctionsService, private dialogSrv: DialogService,
              private router: Router, private authenticationService: AuthenticationService, private dialog: MatDialog,
              private msalBroadcastService: MsalBroadcastService, private authService: MsalService
  ) {

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.loading === false) {
          this.loading = true;
          this.loggedIn = true;
        }
      });

    if (this.authService.instance.getAllAccounts().length > 0) {
      if (this.loading === false) {
        this.loading = true;
        this.loggedIn = true;
      }
    }


    /*this.loggedIn = !!localStorage.getItem('token');
    this.getEmployees();
    authenticationService.tokenValue.subscribe((nextValue) => {
      this.loggedIn = nextValue !== '';
    });*/
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        event.url = event.url.split('?')[0];
        this.currentRoute = event.url.substr(1);
      }
    });
  }

  isExpanded = false;
  loggedIn = false;
  currentRoute = '';
  private readonly _destroying$ = new Subject<void>();
  private loading = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  onLogout() {
    if (this.dialogSrv.modalOpen === false) {
      this.dialogSrv.modalOpen = true;
      this.dialogSrv.openConfirmDialog('Er du sikker på du vil logge af?', 'Log ud', 'Log ud ', 'Annullere')
        .afterClosed().subscribe(res => {
        if (res) {
          this.authenticationService.token = '';
          this.router.navigateByUrl('/login');
        }
        this.dialogSrv.modalOpen = false;
      });
    }
  }
}

import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {CustomerBoxType} from '../models/customerBoxType';

@Injectable({
  providedIn: 'root'
})

export class CustomerService {
  constructor(private http: HttpClient) {
  }

  getCustomerBoxType() {
    return this.http.get('/api/customers/boxType')
      .pipe(
        map((data: CustomerBoxType[]) => {
          return data;
        }));
  }

  getCustomersWithBoxType(customerBoxTypes) {
    return this.http.post('api/customers/customersWithCustomerBoxTypes', customerBoxTypes).pipe(
      map((data: any) => {
        return data;
      }));
  }
}

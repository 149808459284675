import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavbarComponent} from './core/navbar/navbar.component';
import {AppRoutingModule} from './app-routing.module';
import {MaterialModule} from './shared/material/material.module';
import {CommonModule, DatePipe} from '@angular/common';
import {IndexComponent} from './modules/index/index.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {GlobalFunctionsService} from './shared/services/globalFunctions.service';
import {CustomValidators} from './shared/services/custom.validators';
import {MatConfirmDialogComponent} from './shared/dialogs/matConfirmDialog/matConfirmDialog.component';
import {ToastrModule} from 'ngx-toastr';
import {DanishDatePipe} from './shared/pipe/danishDate';
import {DanishCurrencyPipe} from './shared/pipe/danishCurrency';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getDanishPaginatorIntl} from './shared/material/matPaginatorTranslate';
import {default as auth} from './auth-config.js';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration
} from '@azure/msal-angular';
import {TaskViewComponent} from './modules/administrator/taskView/taskView.component';
import {TaskComponent} from './modules/administrator/task/task.component';
import {DatePicker} from './core/date.picker';
import {NgxSpinnerModule} from 'ngx-spinner';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: auth.credentials.clientId,
      authority: 'https://login.microsoftonline.com/' + auth.credentials.tenantId,
      redirectUri: auth.configuration.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(auth.resources.todoListApi.resourceUri, auth.resources.todoListApi.resourceScopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {interactionType: InteractionType.Redirect};
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    IndexComponent,
    DanishDatePipe,
    DanishCurrencyPipe,
    MatConfirmDialogComponent,
    TaskViewComponent,
    TaskComponent,
    DatePicker,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    CommonModule,
    NgbModule,
    ToastrModule.forRoot(),
    MsalModule,
    NgxSpinnerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    CustomValidators, GlobalFunctionsService,
    DatePipe,
    {provide: MatPaginatorIntl, useValue: getDanishPaginatorIntl()}],
  bootstrap: [AppComponent],
  exports: [DatePicker],
})
export class AppModule {
}

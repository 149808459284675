<header id="navbar" class="sticky">
  <nav
    class="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark box-shadow mb-3 fixed-top">
    <div class="container-fluid p-0">
      <a class="navbar-brand" [routerLink]="['/']">
        <img src="../../../assets/img/logo.png" alt=""></a>
      <button class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded"
              (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- (click)="isExpanded=false;" -->
      <div class="navbar-collapse collapse d-sm-inline-flex mr-auto" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow itop-navbar-center">
          <!-- Links -->
          <li class="nav-item">
            <a href="tel:+4570221228" class="nav-link color-primary">
              <i class="fa fa-phone fa-fw mr-2"></i>
              <span>70 221 228</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="mailto:info@itoperators.dk" class="nav-link color-primary">
              <i class="fa fa-envelope fa-fw mr-2"></i>
              <span>info@itoperators.dk</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="https://itoperators.dk/" class="nav-link text-white">HOVEDSIDE</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>



<div class="search-div">
  <button class="btn" (click)="onCreate()"><i
    class="{{globalFunctionsService.plusIcon}}"></i></button>
  <mat-form-field class="search-form-field" floatLabel="never">
    <input matInput [(ngModel)]="searchKey" placeholder="Søg..." autocomplete="off" (keyup)="applyFilter()">
    <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
      <i class="{{globalFunctionsService.closeIcon}}"></i>
    </button>
  </mat-form-field>
</div>

<div class="mat-elevation-z8">
  <mat-table [dataSource]="listData" matSort>
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.title}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.taskStatus.name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="category">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Kategori</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.type}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="error">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Dato fejlmeldt</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.error | danishDate: false}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="solved">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Dato løst</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.solved | danishDate: false}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="modifiedOn">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Oprettet/redigeret</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.modifiedOn| danishDate: true}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="modifiedBy">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Medarbejder</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{globalFunctionsService.findEmployeeNameFromId(element.modifiedBy)}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button class="btn" (click)="onEdit(row)"><i class="{{globalFunctionsService.editIcon}}"></i></button>
        <button class="btn" (click)="onDelete(row)"><i class="{{globalFunctionsService.deleteIcon}}"></i></button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef>
        Indlæser data...
      </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef>
        Ingen data...
      </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':listData!=null}"></mat-footer-row>
    <mat-footer-row *matFooterRowDef="['noData']"
                    [ngClass]="{'hide':!(listData!=null&&listData.data.length==0)}"></mat-footer-row>
  </mat-table>
  <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]=globalFunctionsService.pageSizeOptions
                 [pageSize]=globalFunctionsService.pageSize
                 showFirstLastButtons></mat-paginator>
</div>

import {GlobalFunctionsService} from '../../services/globalFunctions.service';
import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'matConfirmDialog',
  templateUrl: './matConfirmDialog.component.html',
  styleUrls: ['./matConfirmDialog.component.css']
})
export class MatConfirmDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<MatConfirmDialogComponent>, public globalfunctionsService: GlobalFunctionsService) {
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency} from '@angular/common';

@Pipe({
  name: 'danishCurrency',
})
export class DanishCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    // This value changes the text after the number
    currencyCode: string = '',
    display:
      | 'code'
      | 'symbol'
      | 'symbol-narrow'
      | string
      | boolean = 'symbol',
    digitsInfo: string = '3.2-2',
    locale: string = 'da-DK',
  ): string | null {
    return formatCurrency(
      value,
      locale,
      currencyCode,
      digitsInfo,
    );
  }
}

import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Sms} from './notification/sms';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(private http: HttpClient) {
  }

  sendSms(notification) {
    return this.http.post('/api/notification/sms', notification)
      .pipe(
        map((data: Sms) => {
          return data;
        }));
  }

  sendEmail(email) {
    return this.http.post('/api/notification/email', email)
      .pipe(
        map((data: any) => {
          return data;
        }));
  }
}

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  tokenValue = new Subject();
  public Ipv4;


  constructor(private http: HttpClient) {
  }

  loggedIn() {
    return localStorage.getItem('token') != null;
  }

  login(username: string, password: string, Ipv4: string) {
    return this.http.post<any>('api/employee/login', {username, password, Ipv4})
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', user.token);
        this.token = user.token;
      }));
  }

  // Renew token
  renew() {
    return this.http.post<any>('api/employee/RenewLoginSession', null)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', user.token);
        this.token = user.token;
      }));
  }

  logout() {
    // Clear localstorage
    localStorage.clear();
  }

// Saving token in a subject so it possible to subscribe to change
  set token(value) {
    this.tokenValue.next(value);
    if (value === '') {
      this.logout();
    } else {
      localStorage.setItem('token', value);
    }
  }

  get token() {
    return localStorage.getItem('token');
  }

  // This is used for getting local ip
  getLocalIp() {
    this.http.get('https://backup.itoperators.dk/ip').subscribe((res: any) => {
      this.Ipv4 = res;
    });
  }
}

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IndexComponent} from './modules/index/index.component';
import {TaskViewComponent} from './modules/administrator/taskView/taskView.component';
import {MsalGuard} from '@azure/msal-angular';

const routes: Routes = [
  {path: '', component: IndexComponent},
  {path: 'wp-admin', component: TaskViewComponent, canActivate: [MsalGuard]},
  {path: 'administration', component: TaskViewComponent, canActivate: [MsalGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, Validators} from '@angular/forms';
import {TaskService} from '../../../shared/services/task.service';
import {GlobalFunctionsService} from '../../../shared/services/globalFunctions.service';
import {CustomValidators} from '../../../shared/services/custom.validators';
import {TaskStatusService} from '../../../shared/services/taskStatus.service';
import {TaskStatus} from '../../../shared/models/taskStatus';
import {DatePipe} from '@angular/common';
import {Customer} from '../../../shared/models/customer';
import {MatTableDataSource} from '@angular/material/table';
import {CustomerBoxType} from '../../../shared/models/customerBoxType';
import {CustomerService} from '../../../shared/services/customer.service';
import {DialogService} from '../../../shared/dialogs/dialog.service';
import {Sms} from '../../../shared/models/notification/sms';
import {SmsRecipients} from '../../../shared/models/notification/smsRecipients';
import {MailRecipients} from '../../../shared/models/mail/mailNotification/mailRecipients';
import {Mail} from '../../../shared/models/mail/mailNotification/mail';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../shared/models/notification.service';
import {TaskCustomerBoxType} from '../../../shared/models/taskCustomerBoxType';
import {Task} from 'src/app/shared/models/task';

@Component({
  selector: 'task',
  templateUrl: 'task.component.html',
  styleUrls: ['./task.component.scss']
})

export class TaskComponent implements OnInit {
  constructor(public taskService: TaskService, private fb: FormBuilder, private spinner: NgxSpinnerService,
              private toastrService: ToastrService, private taskStatusService: TaskStatusService, public notificationService: NotificationService,
              public globalFunctionsService: GlobalFunctionsService, private datePipe: DatePipe, public customerService: CustomerService,
              public dialogRef: MatDialogRef<TaskComponent>, @Inject(MAT_DIALOG_DATA) public data, public customVal: CustomValidators, private dialogSrv: DialogService) {
  }

  listData: MatTableDataSource<Customer>;
  displayedColumns: string[] = ['name', 'email', 'phone'];
  public taskStatuses: TaskStatus[] = [];
  public randomName = GlobalFunctionsService.randomName;
  public customerBoxTypes: CustomerBoxType[] = [];
  public isLoading = true;
  public smsResponses;
  public lock = false;

  taskForm = this.fb.group({
    title: ['', [Validators.required]],
    description: ['', [Validators.required]],
    error: [''],
    solved: [''],
    taskStatusId: [''],
    subjectNotification: [''],
    descriptionNotification: [''],
  });

  formErrors = {
    'title': '',
    'description': '',
    'error': '',
    'solved': '',
    'subjectNotification': '',
    'descriptionNotification': '',
  };

  ngOnInit() {
    this.listData = new MatTableDataSource();
    this.customerService.getCustomerBoxType().subscribe(
      success => {
        this.customerBoxTypes = success;
        if (this.data) {
          this.data.taskCustomerBoxTypes.forEach((value) => {
            const found = this.customerBoxTypes.find(a => a.customerBoxTypeId === value.customerBoxType.customerBoxTypeId && a.active == true);
            if (found) {
              found.checked = true;
              this.customerBoxTypeChange();
            }
          });
        }
      });

    this.taskStatusService.get().subscribe(
      (success) => {
        this.taskStatuses = success;
      }, err => {
        this.toastrService.error(err.error.message, err.error);
        console.log(err);
      }
    );

    if (this.data) {
      this.taskForm.setValue({
        title: this.data.title,
        description: this.data.description,
        error: this.datePipe.transform(this.data.error, 'dd-MM-yyyy'),
        solved: this.datePipe.transform(this.data.solved, 'dd-MM-yyyy'),
        taskStatusId: this.data.taskStatusId,
        subjectNotification: '',
        descriptionNotification: ''
      });
    } else {
      this.taskForm.get('taskStatusId').setValue(1);
    }

    GlobalFunctionsService.dialogCloseConfirm(this.dialogRef, this.taskForm);
  }

  customerBoxTypeChange() {
    this.customerService.getCustomersWithBoxType(this.customerBoxTypes.filter(a => a.checked)).subscribe(
      success => {
        this.listData.data = success;
      });
  }

  onSubmit() {
    this.customVal.submitClicked = true;
    this.customVal.logValidationErrors(this.taskForm, this.formErrors);
    if (this.taskForm.valid) {
      if (this.data == null) {
        const taskCustomerBoxTypes = [];
        this.customerBoxTypes.forEach((value) => {
          if (value.checked) {
            const taskCustomerBoxType: TaskCustomerBoxType = {
              active: true,
              customerBoxTypeId: value.customerBoxTypeId,
            };
            taskCustomerBoxTypes.push(taskCustomerBoxType);
          }
        });

        const task: Task = {
          active: true,
          createdBy: GlobalFunctionsService.employee.employeeId,
          createdOn: new Date(),
          modifiedBy: GlobalFunctionsService.employee.employeeId,
          modifiedOn: new Date(),
          title: this.taskForm.value.title,
          description: this.taskForm.value.description,
          error: GlobalFunctionsService.danishDateToEnglish(this.taskForm.value.error),
          solved: GlobalFunctionsService.danishDateToEnglish(this.taskForm.value.solved),
          taskStatusId: this.taskForm.value.taskStatusId,
          taskCustomerBoxTypes: taskCustomerBoxTypes,
        };

        this.taskService.insert(task).subscribe(
          success => {
            this.toastrService.success('Oprettede status', 'Administration');
            this.onClose(success);
          }, err => {
            this.toastrService.error(err.error.message, err.error);
            console.log(err);
          }
        );
      } else {
        this.data.title = this.taskForm.value.title;
        this.data.description = this.taskForm.value.description;
        this.data.error = GlobalFunctionsService.danishDateToEnglish(this.taskForm.value.error);
        this.data.solved = GlobalFunctionsService.danishDateToEnglish(this.taskForm.value.solved);
        this.data.taskStatusId = this.taskForm.value.taskStatusId;
        this.data.modifiedBy = GlobalFunctionsService.employee.employeeId;
        this.data.modifiedOn = new Date();

        this.data.taskCustomerBoxTypes.forEach((value) => {
          value.active = false;
        });

        this.customerBoxTypes.forEach((value) => {
          if (value.checked) {
            const found = this.data.taskCustomerBoxTypes.find(a => a.customerBoxTypeId === value.customerBoxTypeId);
            if (found) {
              found.active = true;
            } else {
              const taskCustomerBoxType: TaskCustomerBoxType = {
                active: true,
                customerBoxTypeId: value.customerBoxTypeId,
              };
              this.data.taskCustomerBoxTypes.push(taskCustomerBoxType);
            }
          }
        });

        this.taskService.update(this.data).subscribe(
          success => {
            this.toastrService.success('Opdateret status', 'Administration');
            this.onClose(success);
          }, err => {
            this.toastrService.error(err.error.message, err.error);
            console.log(err);
          }
        );
      }
    }
  }

  onClose(row) {
    this.customVal.submitClicked = false;
    this.taskForm.reset();
    this.dialogRef.close(row);
  }

  sendMail() {
    if (this.dialogSrv.modalOpen === false) {
      this.dialogSrv.modalOpen = true;
      this.dialogSrv.openConfirmDialog('Er du sikker på du vil sende mail', 'Mail notifikation', 'Ja', 'Nej')
        .afterClosed().subscribe(res => {
        if (res) {
          const mailrecipients: MailRecipients[] = [];
          mailrecipients.push({email: 'cp@itoperators.dk'});

          this.listData.data.forEach((value) => {
            if (value.email !== undefined && value.email !== '') {
              // mailrecipients.push({email: value.email});
            }
          });

          const mail: Mail = {
            subject: this.taskForm.value.subjectNotification,
            description: this.taskForm.value.descriptionNotification,
            mailRecipients: mailrecipients,
          };

          this.notificationService.sendEmail(mail).subscribe(
            success => {
              this.lock = false;
              this.globalFunctionsService.successToast('Sende email til ' + mail.mailRecipients.length);
            }, error => {
              this.lock = false;
              this.globalFunctionsService.errorToast('Kunne ikke sende emails!!');
            });
        }
        this.dialogSrv.modalOpen = false;
      });
    }
  }

  sendSms() {
    if (this.dialogSrv.modalOpen === false) {
      this.dialogSrv.modalOpen = true;
      this.dialogSrv.openConfirmDialog('Er du sikker på du vil sende sms', 'Sms notifikation', 'Ja', 'Nej')
        .afterClosed().subscribe(res => {
        if (res) {
          this.lock = true;
          const smsRecipients: SmsRecipients[] = [];
          smsRecipients.push({msisdn: '41412146'});

          this.listData.data.forEach((value) => {
            if (value.phone !== undefined && value.phone !== '') {
              // smsRecipients.push({msisdn: value.phone});
            }
          });

          const sms: Sms = {
            label: 'Itoperators-Drift',
            message: this.taskForm.value.descriptionNotification + '\r\n\r\nMed venlig hilsen IT Operators\r\nDenne sms kan ikke besvares',
            recipients: smsRecipients,
            sender: 'Itoperators'
          };
          this.spinner.show();
          this.notificationService.sendSms(sms).subscribe(
            success => {
              this.smsResponses = success;
              this.lock = false;
              this.globalFunctionsService.successToast('Se sms statusser i bunden');
              this.spinner.hide();
            }, error => {
              this.spinner.hide();
              this.lock = false;
              this.globalFunctionsService.errorToast('Kunne ikke sende sms!!');
            });
        }
        this.dialogSrv.modalOpen = false;
      });
    }
  }
}
